<template>
  <v-list-item>
    <v-list-item-title>
      <!-- <v-card class="pa-2 mt-3 elevation-0">
     
        <a href="javascript:void(0);" @click="showScheduleTestCase();" class="black--text">
          <v-icon class="mr-2">mdi-clock-outline</v-icon>Schedule
        </a>
      </v-card>-->
      <addField
        :path="path"
        :additionalAttributes="additionalAttributes"
        :showDialog="openDialog"
        @dialog-closed="openDialog=false"
      >
        <v-card class="pa-2 mt-3 elevation-0 ">
          <v-icon class="mr-2">mdi-clock-outline</v-icon>{{language.componentLabel.labelSchedule}}
        </v-card>
      </addField>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },
  props: ["info", "actionName", "currentTable", "row", "selectedRows"],
  data() {
    return {
      openDialog: false,
      showNoticeDialog: false,
      path: "serve.php?f=testing&f2=scheduledTestcases",
    };
  },
  computed: {
    additionalAttributes() {
      let additionalAttributes = {};
      if (Object.keys(this.selectedRows).length == 1) {
        let info = this.selectedRows[Object.keys(this.selectedRows)[0]];
        let testCase = info.n_id;

        additionalAttributes = {
          function: "getTestCaseScheduler",
          testCase: testCase,
          requestType: "ajax",
        };
      } else {
        additionalAttributes = {
          function: "getTestCaseSchedulerMulti",
          testCase: Object.keys(this.selectedRows),
          requestType: "ajax",
          callType: "multi",
        };
      }
      return additionalAttributes;
    },
  },

  // methods: {

  //   showScheduleTestCase(testCase) {
  //     if (typeof testCase != "undefined") {
  //       var options = {
  //         function: "getTestCaseScheduler",
  //         testCase: testCase,
  //         requestType: "ajax",
  //       };
  //       var uri = "serve.php?f=testing&f2=scheduledTestcases";
  //       var AdditionalDialogOptions = { width: 650 };
  //       var title = "Schedule Test";
  //       var $returnedDialog = getDialogWithDefaultOptions(
  //         options,
  //         uri,
  //         AdditionalDialogOptions,
  //         title
  //       );
  //     } else {
  //       selected = [];
  //       $(".selectedElements:checked").each(function () {
  //         selected.push($(this).val());
  //       });
  //       if (selected.length > 0) {
  //         //Multi select
  //         var options = {
  //           function: "getTestCaseSchedulerMulti",
  //           testCase: selected,
  //           requestType: "ajax",
  //           callType: "multi",
  //         };
  //         var uri = "serve.php?f=testing&f2=scheduledTestcases";
  //         var AdditionalDialogOptions = { width: 650 };
  //         var title = "Schedule Test (Multiple)";
  //         var $returnedDialog = getDialogWithDefaultOptions(
  //           options,
  //           uri,
  //           AdditionalDialogOptions,
  //           title
  //         );
  //       } else {
  //         showWarn();
  //       }
  //     }
  //   },
  // },
};
</script>